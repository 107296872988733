import styles from "./BasicInput.module.css";
import { validBorder, inValidBorder } from "../../../utils/adminUtils";
interface IBasicInput {
  title: string;
  value?: string | number;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  redBorder: boolean;
  type?: "text" | "password";
}

const BasicInput: React.FC<IBasicInput> = ({
  title,
  value,
  placeholder,
  onChange,
  redBorder,
  type,
}) => {
  return (
    <div className={styles.basicContainer}>
      <h3>{title}</h3>
      <input
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        style={redBorder ? inValidBorder : validBorder}
        type={type ? type : "text"}
      />
    </div>
  );
};
export default BasicInput;
