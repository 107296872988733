import { protocolDataCategory } from "./protocolDataCategoryTypes";

export type protocolData = {
  name: string;
  socialLinks: string[];
  logoURL: string;
  score: number;
  codeBaseLink: string;
  deployedOn: number[];
  category: protocolDataCategory;
  pastAudits: pastAudits;
  secInfra: secInfra;
  codeComplexity: codeComplexity;
  centralization: centralization;
  crossProtocolExposure: crossProtocolExposure;
  recommendations: recommendations;
};

export type blockchain = {
  name: string;
  chainId: number;
  logoURL: string;
  info: string;
};

export type protocolPreview = {
  rid: number;
  name: string;
  score: number;
  logoURL: string;
  auditAmount: number;
};

//May be simplified
export type pastAudits = {
  auditAmount: number;
  reportAmount: number;
  audits: pastAuditReports[];
};

export type pastAuditReports = {
  company: number;
  reportAmount: number;
  date: string;
  link: string;
  reports: vulReport[];
};

//Loaded out of Issue Database
export type vulReport = {
  issueType: string;
  link: string;
  severity: string;
  file: string;
};

export type secInfra = {
  bugBounty: string;
  bugBountyAmount: number;
  paidWhiteHatBefore: string;
  auditCompanyIDs: number[];
  recentSecIndicents: recentNews[];
};

export type recentNews = {
  title: string;
  link: string;
  lostAmount: number;
  date: string;
};

export type auditCompanies = {
  name: string;
  link: string;
  type: auditCompaniesType;
  logoURL: string;
  id: number;
};

export enum auditCompaniesType {
  ContestPlatform,
  Institutional,
  IndependentResearcher,
}

export type codeComplexity = {
  linesOfCode: number;
  amountOfContracts: number;
  amountOfExternalIntegrations: number;
  basicCodeStructure: string;
  mostComplexCodeParts: string;
  lastCodeBaseModification: string;
  similarProtocols: protocolComparison[];
  contractMetrics: contractMetric[];
};

export type protocolComparison = {
  protocolName: string;
  similarityScore: number;
};

export type contractMetric = {
  fileName: string;
  linesOfCode: number;
  nSLOC: number;
  complexityScore: number;
  interfaces: number;
  inherits: string[];
};

export type centralization = {
  centralizationScore: number;
  upgradable: boolean;
  timelocksUsed: boolean;
  timelockInformation: string;
  adminWallet: string;
  multiSigInformation: string;
  adminInformation: string;
  pausable: boolean;
  pausableInformation: string;
  frontendDeployment: string;
  possibilityOfOwnFrontend: string;
  ownFrontendComplexityScore: number;
};

export type crossProtocolExposure = {
  externalIntegrations: externalIntegration[];
  info: string;
};

export type externalIntegration = {
  title: string;
  functionesUsed: string[];
  securityScoreOfIntegration: number;
  description: string;
  integratedProtocolName: string;
};

export type recommendations = {
  howToAvoidRisks: string;
};
