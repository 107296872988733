import { FaFileCode } from "react-icons/fa6";
import styles from "./AuditReportsList.module.css";
import { formatDate } from "../../../../utils/textformUtils";
import {
  auditCompanies,
  codeComplexity,
  pastAudits,
} from "../../../../utils/reportDataTypes";

interface IAuditReportsList {
  auditCompanies: auditCompanies[];
  pastAudits: pastAudits;
  codeData: codeComplexity;
}

export const AuditReportsList: React.FC<IAuditReportsList> = ({
  auditCompanies,
  pastAudits,
  codeData,
}) => {
  return (
    <>
      {pastAudits.audits.length > 0 && (
        <div className={styles.vulReportContainer}>
          <h3>Past Audit Reports</h3>
          <p className={styles.vulReportContainerText}>
            Last codebase change was on:{" "}
            {formatDate(codeData.lastCodeBaseModification)}
          </p>
          <ul className={styles.vulReportList}>
            <li className={styles.stickyRow}>
              <div className={styles.shortListText}> Company </div>
              <div className={styles.shortListText}> Date </div>
              <div className={styles.vulInfo}> Number of Vul Found </div>
              <div className={styles.linkIcon}> Link </div>
            </li>
            {pastAudits.audits.map((audit, i) => {
              return (
                <li
                  key={i}
                  style={
                    i !== pastAudits.audits.length - 1
                      ? {
                          borderBottom:
                            "1px solid var(--ProtocolPage-border-color)",
                          display: "flex",
                        }
                      : { display: "flex" }
                  }
                >
                  <div className={styles.shortListText}>
                    {auditCompanies.map((companie, i) => {
                      return companie.id === audit.company ? (
                        <p key={i}>{companie.name}</p>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                  <div className={styles.shortListText}>
                    {formatDate(audit.date)}
                  </div>
                  <div className={styles.vulInfo}>{audit.reportAmount}</div>
                  <div className={styles.linkIcon}>
                    {" "}
                    <a href={audit.link}>
                      View Report <FaFileCode />
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};
