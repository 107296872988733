import { FaArrowDown, FaRegCircle } from "react-icons/fa";
import styles from "./AdvancedShowMoreButton.module.css";
import { FaRegCircleDot } from "react-icons/fa6";

interface IShowMoreButton {
  text: string;
  onClick: () => void;
  showMore?: boolean;
  progress: number[];
}

const ShowMoreButton: React.FC<IShowMoreButton> = ({
  text,
  onClick,
  showMore,
  progress,
}) => {
  return (
    <div
      className={styles.showMoreButton}
      style={
        !showMore
          ? { borderRadius: "5px 5px 5px 5px" }
          : { borderRadius: "5px 5px 0px 0px" }
      }
      onClick={onClick}
    >
      {!showMore ? (
        <FaRegCircle style={{ width: "5%" }} />
      ) : (
        <FaRegCircleDot style={{ width: "5%" }} />
      )}
      <b style={{ width: "80%" }}>{text}</b>
      <p className={styles.smallText}>
        Completed {progress[0]}/{progress[1]}
      </p>
      <FaArrowDown
        className={`fas-edonec fa-chevron-down-edonec ${
          showMore ? "rotate-center-edonec down" : "rotate-center-edonec up"
        }`}
        style={{ width: "5%" }}
      />
    </div>
  );
};

export default ShowMoreButton;
