import styles from "./AllProtocolsPage.module.css";
import { getReportPreviewsURL, getRequestCustomReportURL } from "../../api";
import { useEffect, useState } from "react";
import { protocolPreview } from "../../utils/reportDataTypes";
import RatingBar from "../../components/RatingBar";
import { Link } from "react-router-dom";
import SearchBar from "../../components/Reusable/SearchBar";
import { sendRequest } from "../../utils/apiRequest";

interface IAllProtocolsPage {
  setCurrentProtocolID: (addProtocolActive: number) => void;
}
const AllProtocolsPage: React.FC<IAllProtocolsPage> = ({
  setCurrentProtocolID,
}) => {
  const [allProtocols, setAllProtocols] = useState<protocolPreview[]>([
    {
      name: "Uniswap",
      rid: 0,
      score: 85,
      logoURL:
        "https://img.cryptorank.io/exchanges/uniswap_v_3_base1697812078663.png",
      auditAmount: 2,
    },
  ]);
  const [protocolSearch, setProtocolSearch] = useState("");

  const handleProtocolSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProtocolSearch(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await sendRequest(
        getReportPreviewsURL,
        "Error getting Protocols",
        undefined,
        undefined,
        setAllProtocols,
      );
    };
    fetchData();
  }, []);

  return (
    <div className={styles.publications}>
      <div className={styles.container}>
        <div className={styles.reportContainer}>
          <div className={styles.header}>
            <h3>Protocols</h3>
            <a href={getRequestCustomReportURL} className={styles.headerButton}>
              Request Report
            </a>
          </div>
          <SearchBar
            value={protocolSearch}
            onChange={handleProtocolSearchChange}
            placeholder="Search for protocol"
          />
          <ul className={styles.CompanieList}>
            <li className={styles.row}>
              <p>Protocol</p>
              <p>Protocol Audit Amount</p>
              <p className={styles.protocolRating}>Protocol Rating</p>
            </li>
            {allProtocols
              .filter((protocol) =>
                protocol.name
                  .toLowerCase()
                  .startsWith(protocolSearch.toLowerCase()),
              )
              .map((protocol) => (
                <Link
                  onClick={() => setCurrentProtocolID(protocol.rid)}
                  to={`/protocol/${protocol.name}`}
                  key={protocol.name}
                  className={styles.row}
                >
                  <div className={styles.rowNameContainer}>
                    <img
                      alt=""
                      src={protocol.logoURL}
                      className={styles.image}
                    />
                    <p>{protocol.name}</p>
                  </div>
                  <p>{protocol.auditAmount}</p>
                  <div className={styles.rating}>
                    <RatingBar
                      rating={protocol.score}
                      color1={"#FF6384"}
                      color2={"#FFCE56"}
                      color3={"#4BC0C0"}
                    />
                  </div>
                </Link>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllProtocolsPage;
