import styles from "./Recomendations.module.css";
import { recommendations } from "../../../utils/reportDataTypes";
import React from "react";
import { MdGppGood } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
interface IRecomendations {
  recommendations: recommendations;
}

const Recomendations = React.forwardRef<HTMLDivElement, IRecomendations>(
  ({ recommendations }, ref) => {
    if (recommendations.howToAvoidRisks !== undefined) {
      if (recommendations.howToAvoidRisks.length < 300) {
        return (
          <>
            <div className={styles.container} ref={ref}>
              <h2>Recommendations</h2>
              <div className={styles.alertContainer}>
                <div className={styles.icon}>
                  <MdGppGood size={35} />
                </div>
                <p>{recommendations.howToAvoidRisks}</p>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className={styles.container} ref={ref}>
              <h2>Recommendations</h2>
              <p>{recommendations.howToAvoidRisks}</p>
            </div>
          </>
        );
      }
    }
    return (
      <>
        <div className={styles.container} ref={ref}>
          <h2>Recommendations</h2>
          <div className={styles.alertContainerNoReco}>
            <div className={styles.IconNoReco}>
              <FaCircleCheck />
            </div>
            <p> SCAS has found nothing to consider when using this protocol!</p>
          </div>
        </div>
      </>
    );
  },
);

export default Recomendations;
