import React, { useState } from "react";
import commonFormStyles from "../../../styles/common/form.module.css";
import { isPasswordValid, isValidEmail } from "../../../utils/validation";
import { useTheme } from "../../../hooks/useTheme";
import FormInput from "../../Reusable/FormInput";
import { Eye, EyeOff } from "lucide-react";
import styles from "./RegisterFormStep.module.css";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import stepStyles from '../OnboardingStep.module.css';

interface IFormData {
  email: string;
  password: string;
}

interface RegisterFormProps {
  handleRegisterSubmit: (formData: IFormData) => Promise<void>;
  buttonText: string;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  handleRegisterSubmit,
  buttonText,
}) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const isDarkTheme = useTheme();

  const handleSubmit = () => {
    setFormError("");
    if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !confirmPassword ||
      !agreeToTerms
    ) {
      setFormError(
        "Please fill in all fields and agree to the Terms & Conditions.",
      );
      return;
    }
    if (!isValidEmail(email)) {
      setFormError("Please enter a valid email address.");
      return;
    }
    if (!isPasswordValid(password)) {
      setFormError(
        "Password must be at least 8 characters long and include an uppercase letter, a number, and a symbol.",
      );
      return;
    }
    if (password !== confirmPassword) {
      setFormError("Passwords do not match.");
      return;
    }
    handleRegisterSubmit({ email, password });
  };

  return (
    <div
      className={`${commonFormStyles.form} ${
        isDarkTheme ? commonFormStyles["form--dark"] : ""
      } ${stepStyles['onboarding-step']}`}
    >
      <div className={commonFormStyles["form-header"]}>
        <h2 className={commonFormStyles["form-header__title"]}>
          Create Account
        </h2>
        <p className={commonFormStyles["form-header__subtitle"]}>
          Already have an account?{" "}
          <a className={commonFormStyles["form-header__link"]} href="/account">
            Log in
          </a>
        </p>
      </div>

      <div className={commonFormStyles["form-row"]}>
        <FormInput
          type="text"
          placeholder="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          containerClassName={commonFormStyles["form-row__item"]}
        />
        <FormInput
          type="text"
          placeholder="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          containerClassName={commonFormStyles["form-row__item"]}
        />
      </div>

      <FormInput
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <div className={commonFormStyles["form-row"]}>
        <FormInput
          type={showPassword ? "text" : "password"}
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles["form-field__input"]}
          icon={
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className={styles["form-field__password-toggle"]}
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          }
          containerClassName={`${commonFormStyles["form-row__item"]} ${styles["form-field"]}`}
        />
        <FormInput
          type={showPassword ? "text" : "password"}
          placeholder="Confirm password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={styles["form-field__input"]}
          icon={
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className={styles["form-field__password-toggle"]}
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          } 
          containerClassName={`${commonFormStyles["form-row__item"]} ${styles["form-field"]}`}
        />
      </div>

      <div className={commonFormStyles["form-checkbox"]}>
        <input
          type="checkbox"
          id="terms"
          className={commonFormStyles["form-checkbox__input"]}
          checked={agreeToTerms}
          onChange={(e) => setAgreeToTerms(e.target.checked)}
        />
        <label
          htmlFor="terms"
          className={commonFormStyles["form-checkbox__label"]}
        >
          I agree to the Terms & Conditions
        </label>
      </div>

      {formError && (
        <p className={commonFormStyles["form-error"]}>{formError}</p>
      )}

      <button
        className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
        onClick={handleSubmit}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default RegisterForm;
