import styles from "./PreRegisterPage.module.css";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";
import RegisterWorkflow from "../../components/OnboardingWorkflow";
import { Link } from "react-router-dom";

import { useTheme } from "../../hooks/useTheme";
import inputControlStyles from "../../styles/common/inputControls.module.css";

interface IPreRegisterPage {
  user: Object | null;
  setUser: (user: any) => void;
}

const PreRegisterPage: React.FC<IPreRegisterPage> = ({ user, setUser }) => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [isProSignUp, setIsProSignUp] = useState(false);
  const isDarkTheme = useTheme();

  const handleRegisterChange = (isProSignUp?: boolean) => {
    setShowSignUp((prev) => !prev);
    if (isProSignUp !== undefined) {
      setIsProSignUp(isProSignUp);
    }
  };

  const features = [
    "Request up to 2 custom reports per month",
    "API access with up to 10.000 requests per month",
    "Create custom search querys",
    "Create custom graphs directly in SCAS",
    "Search 300+ codebases and 1000+ past audit reports",
  ];
  const featuresFree = [
    "Search all data on SCAS",
    "API access with up to 500 requests per month",
  ];

  if (showSignUp) {
    return (
      <div className={styles.container}>
        <RegisterWorkflow
          setUser={setUser}
          user={user}
          setIsRegisterPopUpVisible={() => handleRegisterChange(false)}
          isProSignUp={isProSignUp}
        />
      </div>
    );
  }
  return (
    <div className={`${styles.payment} ${isDarkTheme ? "dark-theme" : ""}`}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.getProContainer}>
            <h3>Get SCAS Pro</h3>
            <div className={styles.description}>
              <p>Get SCAS Pro to access our codebase, access API and more</p>
            </div>
            <ul className={styles.featureList}>
              {features.map((feature) => {
                return (
                  <li key={feature} className={styles.row}>
                    <FaCheck /> {feature}
                  </li>
                );
              })}
            </ul>
            <div className={styles.pricing}>
              <p>- 499 USDC / user / 6 Month </p>
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
                onClick={() => handleRegisterChange(true)}
                style={{ fontSize: "14px" }}
              >
                Get SCAS Pro
              </button>
            </div>
            <p className={styles.greyText}>
              Already have an account? <a href="./account">Login</a>
            </p>
          </div>
          <div className={styles.getProContainer}>
            <h3>Create a free SCAS account</h3>
            <div className={styles.description}>
              <p>Get a free SCAS account to get limited access to api</p>
            </div>
            <ul className={styles.featureList}>
              {featuresFree.map((feature) => {
                return (
                  <li key={feature} className={styles.row}>
                    <FaCheck /> {feature}
                  </li>
                );
              })}
            </ul>
            <div className={styles.pricing}>
              <p>- Contribute to SCAS to get 3 months SCAS Pro. Learn more</p>
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
                onClick={() => handleRegisterChange(false)}
                style={{ fontSize: "14px" }}
              >
                Create Account
              </button>
            </div>
            <p className={styles.greyText}>
              Already have an account? <Link to="../account">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreRegisterPage;
