import React, { useState } from "react";
import commonFormStyles from "../../../styles/common/form.module.css";
import inputControlStyles from "../../../styles/common/inputControls.module.css";
import FormInput from "../../../components/Reusable/FormInput";
import { useTheme } from "../../../hooks/useTheme";
import { Eye, EyeOff } from "lucide-react";

interface LoginFormProps {
  onLogin: (email: string, password: string) => Promise<void>;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isDarkTheme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onLogin(email, password);
  };

  return (
    <div
      className={`${commonFormStyles.form} ${isDarkTheme ? commonFormStyles["form--dark"] : ""}`}
    >
      <div className={commonFormStyles["form-header"]}>
        <h2 className={commonFormStyles["form-header__title"]}>Log in</h2>
        <p className={commonFormStyles["form-header__subtitle"]}>
          Don't have an account?{" "}
          <a
            className={commonFormStyles["form-header__link"]}
            href="/join-beta"
          >
            Sign up
          </a>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={commonFormStyles["form-field"]}>
          <FormInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={commonFormStyles["form-field__input"]}
          />
        </div>
        <div
          className={`${commonFormStyles["form-field"]} ${commonFormStyles["form-field--with-icon"]}`}
        >
          <FormInput
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={commonFormStyles["form-field__input"]}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className={commonFormStyles["form-field__icon"]}
          >
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </button>
        </div>
        <button
          type="submit"
          className={`${inputControlStyles.button} ${inputControlStyles["button--primary"]}`}
        >
          Log in
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
