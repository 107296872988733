import React, { useEffect, useRef, useState } from "react";
import AdvancedShowMoreButton from "../../PublishPageComponents/AdvancedShowMoreButton";
import styles from "./Collapsible.module.css";

interface IProps {
  open?: boolean;
  title: string;
  progress: number[];
  children: JSX.Element;
}

const Collapsible: React.FC<IProps> = ({ open, children, title, progress }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(
    open ? undefined : 0,
  );
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };
  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <>
      <AdvancedShowMoreButton
        text={title}
        progress={progress}
        showMore={isOpen}
        onClick={handleFilterOpening}
      />
      <div className={styles.sectionContainer} style={{ height }}>
        <div className={styles.refContainer} ref={ref}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Collapsible;
