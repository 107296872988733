import { reportFeedbackURL } from "../../../api";
import styles from "./Disclaimer.module.css";
import React from "react";

const Disclaimer = React.forwardRef<HTMLDivElement>(() => {
  return (
    <>
      <div className={styles.container}>
        <h4>Disclaimer:</h4>
        <p>
          SCAS conducts security assessments on the provided source code
          exclusively. Conduct your own due diligence before deciding to use any
          info listed at this page.
          <br />
          <br />
          In case you find any information on this report is incorrect, please
          fill out following form:
          <a href={reportFeedbackURL}>Feedback</a>
        </p>
      </div>
    </>
  );
});

export default Disclaimer;
