import styles from "./CrossProtocolExposure.module.css";
import { crossProtocolExposure } from "../../../utils/reportDataTypes";
import React from "react";
import { FaCircleCheck } from "react-icons/fa6";

interface ICrossProtocolExposure {
  crossProtocolExposure: crossProtocolExposure;
}

const CrossProtocolExposure = React.forwardRef<
  HTMLDivElement,
  ICrossProtocolExposure
>(({ crossProtocolExposure }, ref) => {
  if (crossProtocolExposure.externalIntegrations.length > 0) {
    return (
      <>
        <div className={styles.container} ref={ref}>
          <h2>Cross Protocol Exposure</h2>
          <h3>All External Integrations:</h3>
          {crossProtocolExposure.externalIntegrations.map((item) => {
            return <p key={item.integratedProtocolName}></p>;
          })}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.container} ref={ref}>
          <h2>Cross Protocol Exposure</h2>
          <div className={styles.alertContainer}>
            <div className={styles.icon}>
              <FaCircleCheck />
            </div>
            <p> There are no external Integrations!</p>
          </div>
        </div>
      </>
    );
  }
});

export default CrossProtocolExposure;
