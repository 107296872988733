import { useEffect, useState } from "react";
import styles from "./PublishPage.module.css";
import AddProtocolPage from "./AddProtocol";
import { AddBlockchain } from "../../components/PublishPageComponents/AddBlockchain";
import { userData } from "../../utils/severRequestTypes";
import { protocolData, protocolPreview } from "../../utils/reportDataTypes";
import { getReportPreviewsUnderReviewURL, getUnderReviewReportDataURL } from "../../api";
import { sendRequest } from "../../utils/apiRequest";
import { AddAuditCompany } from "../../components/PublishPageComponents/AddAuditCompany";
import { Link } from "react-router-dom";

interface IPublishPage {
  user: userData | null;
  setCurrentProtocolID: (addProtocolActive: number) => void;
}

const PublishPage: React.FC<IPublishPage> = ({
  user,
  setCurrentProtocolID,
}) => {
  const [isAdmin] = useState<boolean>(false);
  const [addProtocolPageDisplay, setAddProtocolPageDisplay] = useState(false);
  const [addAuditCompanyPageDisplay, setAddAuditCompanyPageDisplay] =
    useState(false);
  const [addBlockChainPageDisplay, setAddBlockChainPageDisplay] =
    useState(false);

  const [allprotocols, setAllprotocols] = useState<protocolPreview[]>([
    { rid: 0, name: "", logoURL: "", auditAmount: -1, score: -1 },
  ]);
  const [currentProtocol, setCurrentProtocol] = useState<protocolPreview>({
    rid: 0,
    name: "",
    logoURL: "",
    auditAmount: -1,
    score: -1,
  });
  const [currentProtocolData, setCurrentProtocolData] =
    useState<protocolData | null>(null);

  useEffect(() => {
    const fetchProtocolPreviews = async () => {
      await sendRequest(
        getReportPreviewsUnderReviewURL,
        "Something went wrong while getting past reports!",
        undefined,
        undefined,
        setAllprotocols,
      );
    };
    fetchProtocolPreviews();
  }, []);

  const getCurrentProtocolData = async (currentProtocolID: number) => {
    const id = [currentProtocolID];
    await sendRequest(
      getUnderReviewReportDataURL,
      "Something went wrong while getting past reports!",
      undefined,
      id,
      setCurrentProtocolData,
    );
  };

  const setCurrentProtocolAndOpenPage = async (
    protocolPreview: protocolPreview,
  ) => {
    setCurrentProtocolData(null);
    setCurrentProtocol({ ...protocolPreview });
    await getCurrentProtocolData(protocolPreview.rid);
    setAddProtocolPageDisplay(true);
  };

  const setAddProtocolPageFalse = () => {
    setAddProtocolPageDisplay(false);
    setCurrentProtocolData(null);
    setCurrentProtocol({
      rid: -1,
      name: "",
      logoURL: "",
      auditAmount: -1,
      score: -1,
    });
  };

  if (user !== null) {
    if (
      addProtocolPageDisplay &&
      !addAuditCompanyPageDisplay &&
      !addBlockChainPageDisplay
    ) {
      return (
        <AddProtocolPage
          protocolData={currentProtocolData}
          setAddProtocolActive={setAddProtocolPageFalse}
          protocolPreview={currentProtocol}
        />
      );
    } else if (
      addAuditCompanyPageDisplay &&
      !addBlockChainPageDisplay &&
      !addProtocolPageDisplay
    ) {
      return (
        <div className={styles.container}>
          <AddAuditCompany
            setAuditCompanyIsActive={setAddAuditCompanyPageDisplay}
          />
        </div>
      );
    } else if (addBlockChainPageDisplay) {
      return (
        <div className={styles.container}>
          <AddBlockchain setAddBlockchainActive={setAddBlockChainPageDisplay} />
        </div>
      );
    } else {
      return (
        <div className={styles.buttonContainer}>
          <div className={styles.containerInner} style={{ width: "30%" }}>
            <h3>Create new Report</h3>
            <span>
              Create a new report for a protocol not added to SCAS yet
            </span>
            <button
              className={styles.saveButton}
              onClick={() => setAddProtocolPageDisplay(!addProtocolPageDisplay)}
            >
              {" "}
              Add Protocol{" "}
            </button>
            <button
              className={styles.saveButton}
              onClick={() =>
                setAddAuditCompanyPageDisplay(!addAuditCompanyPageDisplay)
              }
            >
              {" "}
              Add Audit Company{" "}
            </button>
            <button
              className={styles.saveButton}
              onClick={() =>
                setAddBlockChainPageDisplay(!addBlockChainPageDisplay)
              }
            >
              {" "}
              Add Blockchain{" "}
            </button>
          </div>
          <div className={styles.containerInner} style={{ width: "70%" }}>
            <h3>Edit Protocol Entries</h3>
            <span>
              Following reports are not published yet and cant be viewed on the
              main page.
            </span>
            <ul className={styles.ReportList}>
              {allprotocols.map((protocol) => {
                return (
                  <li className={styles.row} key={protocol.rid}>
                    <h4 className={styles.IDtext}>{protocol.rid}</h4>
                    <img
                      className={styles.logo}
                      src={protocol.logoURL}
                      alt=""
                    />
                    <p className={styles.text}>{protocol.name}</p>
                    <p
                      className={styles.text}
                      style={{ color: "rgba(255, 159, 64, 1)" }}
                    >
                      Under Review
                    </p>
                    {isAdmin ? (
                      <button className={styles.editButton}>Publish</button>
                    ) : (
                      ""
                    )}
                    <Link
                      to={`/protocol/${protocol.name}?id=${protocol.rid}&underReview=1`}
                      onClick={() => setCurrentProtocolID(protocol.rid)}
                    >
                      View
                    </Link>
                    <button
                      className={styles.editButton}
                      onClick={() => setCurrentProtocolAndOpenPage(protocol)}
                    >
                      {" "}
                      Edit{" "}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
  } else {
    return <>Error Please Login</>;
  }
};

export default PublishPage;
