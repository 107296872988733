import styles from "./SearchBar.module.css";
import { FaSearch } from "react-icons/fa";
import React from "react";

interface ISearchBar {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

const SearchBar: React.FC<ISearchBar> = ({ value, onChange, placeholder }) => {
  return (
    <div className={styles.searchBarWrapper}>
      <FaSearch />
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      ></input>
    </div>
  );
};

export default SearchBar;
