import styles from "./SecInfra.module.css";
import {
  secInfra,
  auditCompanies,
  protocolData,
} from "../../../utils/reportDataTypes";
import { isNumber } from "../../../utils/adminUtils";
import { useState, useEffect } from "react";
import BasicInput from "../../Reusable/BasicInput";
import BasicList from "../../Reusable/BasicList";
import axios from "axios";
import { getAllAuditCompaniesURL, writePastAuditDataURL } from "../../../api";
import {
  auditDataSubmittRequest,
  auditReportLink,
} from "../../../utils/severRequestTypes";
import { sendRequest } from "../../../utils/apiRequest";
import PurpleButton from "../../Reusable/PurpleButton";

interface ISecInfra {
  protocolReportID: number;
  protocolData: protocolData | null;
  setProgress: (newA: number[]) => void;
}

export const SecInfra: React.FC<ISecInfra> = ({
  protocolReportID,
  protocolData,
  setProgress,
}) => {
  const [allAuditCompanies, setAllAuditCompanies] = useState<auditCompanies[]>(
    [],
  );
  const [requestSend, setReuqestSend] = useState<boolean>(false);
  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0]);
  const initialArray : auditReportLink[] = protocolData ? protocolData.pastAudits.audits.map(({ link, company }) => ({auditReportLink: link,auditedBy: company })) : [];
  const [auditReportLinksObj, setAuditReportLinksObj] = useState<
    auditReportLink[]
  >(initialArray);
  const [secData, setSecData] = useState<secInfra>({
    bugBounty: protocolData
      ? protocolData.secInfra.bugBounty === null
        ? ""
        : protocolData.secInfra.bugBounty
      : "",
    bugBountyAmount: protocolData ? protocolData.secInfra.bugBountyAmount : 0,
    paidWhiteHatBefore: protocolData
      ? protocolData.secInfra.paidWhiteHatBefore
      : "",
    auditCompanyIDs: [],
    recentSecIndicents: [],
  });

  useEffect(() => {
    const sendGetProductsRequest = async () => {
      try {
        const { data } = await axios.post(getAllAuditCompaniesURL, "{}", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const products: auditCompanies[] = data;
        setAllAuditCompanies(
          products.sort((a, b) => a.name.localeCompare(b.name)),
        );
      } catch (e) {
        console.log(e);
      }
    };

    sendGetProductsRequest();
  }, []);

  const sendWritePastAuditDataRequest = async () => {
    setReuqestSend(true);
    const request: auditDataSubmittRequest = {
      protocolReportID: protocolReportID,
      pastAuditLinks: auditReportLinksObj,
      secData: secData,
    };
    sendRequest(
      writePastAuditDataURL,
      "Something went wrong while write!",
      "Added Data!",
      request,
    );
  };

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 4]);
    }
  };

  if (protocolData && protocolData.secInfra !== undefined) {
    if (protocolData.secInfra.bugBounty !== null) {
      if (protocolData.secInfra.bugBounty.length > 0) {
        setProgressHelper(1);
      }
    }
    if (protocolData.secInfra.bugBountyAmount !== null) {
      if (protocolData.secInfra.bugBountyAmount > 0) {
        setProgressHelper(2);
      }
    }
    if (protocolData.secInfra.paidWhiteHatBefore !== null) {
      if (protocolData.secInfra.paidWhiteHatBefore.length > 0) {
        setProgressHelper(3);
      }
    }
    if (protocolData.pastAudits !== null) {
      if (protocolData.pastAudits.audits !== null) {
        if (protocolData.pastAudits.audits.length > 0) {
          setProgressHelper(0);
        }
      }
    }
  }

  const handleAddAuditLink = () => {
    secData.auditCompanyIDs.push(0);
    setSecData({ ...secData });

    const arr2: auditReportLink[] = auditReportLinksObj.concat([
      { auditReportLink: "", auditedBy: 0 },
    ]);
    setAuditReportLinksObj([...arr2]);
    setProgressHelper(0);
  };

  const handleAddAuditLinkChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      auditReportLinksObj[index].auditReportLink = e.target.value;
      setAuditReportLinksObj([...auditReportLinksObj]);
    };

  const handleRemoveAuditLink = (index: number) => {
    const arr2: auditReportLink[] = auditReportLinksObj;
    arr2.splice(index, 1);
    setAuditReportLinksObj([...arr2]);

    delete secData.auditCompanyIDs[index];
    setSecData({ ...secData });
  };

  const handleBugBountyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    secData.bugBounty = e.target.value;
    setSecData({ ...secData });
    setProgressHelper(1);
  };

  const handleBugBountyAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let num = Number(e.target.value);
    if (isNumber(num)) {
      secData.bugBountyAmount = num;
      setSecData({ ...secData });
      setProgressHelper(2);
    }
  };

  const handlePaidWhiteHatBefore = (e: React.ChangeEvent<HTMLInputElement>) => {
    secData.paidWhiteHatBefore = e.target.value;
    setSecData({ ...secData });
    setProgressHelper(3);
  };

  const handleSelectChange =
    (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      secData.auditCompanyIDs[index] = Number(event.target.value);
      auditReportLinksObj[index].auditedBy = Number(event.target.value);
      setAuditReportLinksObj([...auditReportLinksObj]);
      setSecData({ ...secData });
    };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <BasicInput
            value={secData.bugBounty}
            title={"Bug bounty"}
            placeholder={"https://..."}
            onChange={handleBugBountyChange}
            redBorder={false}
          />
          <BasicInput
            value={secData.bugBountyAmount}
            title={"Bug bounty amount in USD"}
            placeholder={"15"}
            onChange={handleBugBountyAmountChange}
            redBorder={false}
          />
          <BasicInput
            value={secData.paidWhiteHatBefore}
            title={"Paid whitehat before"}
            placeholder="Paid whitehats when..."
            onChange={handlePaidWhiteHatBefore}
            redBorder={false}
          />
        </div>
        <div className={styles.containerInner}>
          <BasicList
            gap={40}
            title="Audit Report Links"
            addButtonText="Audit Report"
            handleRemove={handleRemoveAuditLink}
            handleAdd={handleAddAuditLink}
            height={300}
            list={auditReportLinksObj}
            items={[
              {
                type: "input",
                property: "auditReportLink",
                onChange: handleAddAuditLinkChange,
                title: "",
                placeholder: "https://",
                borderIsValid: [],
              },
              {
                type: "select",
                property: "auditedBy",
                onChange: handleSelectChange,
                title: "",
                value: "id",
                display: "name",
                options: allAuditCompanies,
              },
            ]}
          />
        </div>
      </div>
      <PurpleButton
        width="90%"
        height="3rem"
        text="Update Audit Data"
        onClick={sendWritePastAuditDataRequest}
        disabled={protocolReportID <= 0 || requestSend}
      />
      <p style={{ color: "var(--font-color)" }}>
        * This request may take up to 30 min to upload data successfully
      </p>
    </>
  );
};
