import React from "react";
import { Loader2 } from "lucide-react";
import styles from "./CircularProgress.module.css";
import { useTheme } from "../../../hooks/useTheme";

interface CircularProgressProps {
  className?: string;
}

const CircularProgress: React.FC<CircularProgressProps> = ({ className }) => {
  const isDarkTheme = useTheme();

  return (
    <div className={styles.circular_progress}>
      <Loader2
        className={`${styles.circular_progress__spinner} ${className || ""}`}
        color={isDarkTheme ? "white" : "black"}
      />
    </div>
  );
};

export default CircularProgress;
