import React from "react";
import { useTheme } from "../../../hooks/useTheme";
import PurpleButton from "../../Reusable/PurpleButton";
import styles from "./PaymentStep.module.css";
import stepStyles from "../OnboardingStep.module.css";

const usdcLink =
  "https://polygonscan.com/token/0x3c499c542cef5e3811e1192ce70d8cc03d5c3359";

interface IPaymentStep {
  handelShowFinish: () => void;
  wallet: string;
}

const PaymentStep: React.FC<IPaymentStep> = ({
  wallet,
  handelShowFinish,
}) => {
  const isDarkTheme = useTheme();

  return (
    <div
      className={`${styles["payment-page"]} ${
        isDarkTheme ? styles.dark : styles.light
      } ${stepStyles["onboarding-step"]}`}
    >
      <h2 className={styles["payment-page__title"]}>
        Purchase 6 Month SCAS Pro
      </h2>
      <div className={styles["payment-page__description"]}>
        <p>
          On purchase, Metamask will ask you to transfer 499{" "}
          <a
            href={usdcLink}
            className={styles["payment-page__link"]}
            target="_blank"
            rel="noopener noreferrer"
          >
            USDC
          </a>{" "}
          to SCAS. You will have to sign the transaction and wait for it to be
          mined.
        </p>
      </div>

      <p className={styles["payment-page__support-text"]}>
        If you need help or your transfer went through but you get any error,
        please contact our support.
      </p>

      <div className={styles["payment-page__button-wrapper"]}>
        <PurpleButton
          fixedsize={true}
          disabled={wallet.length < 2}
          text="Purchase"
          onClick={handelShowFinish}
        />
      </div>
    </div>
  );
};

export default PaymentStep;
