import styles from "./Centralization.module.css";
import { useState } from "react";
import BasicInput from "../../Reusable/BasicInput";
import { centralizationDataSubmittRequest } from "../../../utils/severRequestTypes";
import BasicSelect from "../../Reusable/BasicSelect";
import { writeCentralizationDataURL } from "../../../api";
import { sendRequest } from "../../../utils/apiRequest";
import PurpleButton from "../../Reusable/PurpleButton";
import { protocolData } from "../../../utils/reportDataTypes";

interface ICentralization {
  protocolId: number;
  protocolData: protocolData | null;
  setProgress: (newA: number[]) => void;
}

const Centralization: React.FC<ICentralization> = ({
  protocolId,
  protocolData,
  setProgress,
}) => {
  const [timeLockInformation, setTimeLockInformation] = useState<string>(
    protocolData
      ? protocolData.centralization.timelockInformation !== undefined
        ? protocolData.centralization.timelockInformation
        : ""
      : "",
  );
  const [adminWallet, setAdminWallet] = useState<string>(
    protocolData ? protocolData.centralization.adminWallet : "",
  );
  const [adminInformation, setAdminInformation] = useState<string>(
    protocolData ? protocolData.centralization.adminInformation : "",
  );
  const [pauseInformation, setPauseInformation] = useState<string>(
    protocolData ? protocolData.centralization.pausableInformation : "",
  );
  const [frontendInformation, setFrontendInformation] = useState<string>(
    protocolData ? protocolData.centralization.frontendDeployment : "",
  );
  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0, 0]);
  const [ownFrontendInformation, setOwnFrontendInformation] = useState<string>(
    protocolData ? protocolData.centralization.possibilityOfOwnFrontend : "",
  );
  const [offChainMaintanceScore, setOffChainMaintanceScore] = useState<number>(
    protocolData ? protocolData.centralization.ownFrontendComplexityScore : 1,
  );

  const sendPublishReportDataRequest = async () => {
    const request: centralizationDataSubmittRequest = {
      reportID: protocolId,
      adminWallet: adminWallet,
      adminInformation: adminInformation,
      pausableInformation: pauseInformation,
      timelockInformation: timeLockInformation,
      frontendDeployment: frontendInformation,
      possibilityOfOwnFrontend: ownFrontendInformation,
      ownFrontendComplexityScore: offChainMaintanceScore,
    };
    console.log(request);

    sendRequest(
      writeCentralizationDataURL,
      "Something went wrong while uploading data!",
      "Centralization added!",
      request,
    );
  };

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 5]);
    }
  };
  if (protocolData && protocolData.centralization !== undefined) {
    if (protocolData.centralization.adminWallet.length > 0) {
      setProgressHelper(1);
    }
    if (protocolData.centralization.adminInformation.length > 0) {
      setProgressHelper(2);
    }
    if (protocolData.centralization.pausableInformation.length > 0) {
      setProgressHelper(0);
    }
    if (protocolData.centralization.frontendDeployment.length > 0) {
      setProgressHelper(3);
    }
    if (protocolData.centralization.possibilityOfOwnFrontend.length > 0) {
      setProgressHelper(4);
    }
  }
  const handleTimeLockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeLockInformation(e.target.value);
  };
  const handelAdminWalletChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminWallet(e.target.value);
    setProgressHelper(1);
  };
  const handelAdminInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdminInformation(e.target.value);
    setProgressHelper(2);
  };
  const handelPauseInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPauseInformation(e.target.value);
    setProgressHelper(0);
  };
  const handelFrontendInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFrontendInformation(e.target.value);
    setProgressHelper(3);
  };
  const handelOwnFrontendInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOwnFrontendInformation(e.target.value);
    setProgressHelper(4);
  };

  const handelOffChainScoreChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = Number(e.target.value);
    setOffChainMaintanceScore(value);
  };

  const selectValues = [
    { name: "Excellent (1)", id: 1 },
    { name: "Average (2)", id: 2 },
    { name: "Poor (3)", id: 3 },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <BasicInput
            title="Information about Timelocks"
            value={timeLockInformation}
            redBorder={false}
            placeholder="Timelock on Stake..."
            onChange={handleTimeLockChange}
          />
          <BasicInput
            title="Admin wallet or multisig address"
            value={adminWallet}
            redBorder={false}
            placeholder="0x..."
            onChange={handelAdminWalletChange}
          />
          <BasicInput
            title="Information about Admin"
            value={adminInformation}
            redBorder={false}
            placeholder="Admin can ..."
            onChange={handelAdminInfoChange}
          />
          <BasicInput
            title="Information about Pause"
            value={pauseInformation}
            redBorder={false}
            placeholder="Can be paused.."
            onChange={handelPauseInfoChange}
          />
        </div>
        <div className={styles.containerInner}>
          <BasicInput
            title="Frontend Link (App)"
            value={frontendInformation}
            redBorder={false}
            placeholder="https://..."
            onChange={handelFrontendInformationChange}
          />
          <BasicInput
            title="Information about 3rd party Frontend Deployment"
            value={ownFrontendInformation}
            redBorder={false}
            placeholder="Front end is possible..."
            onChange={handelOwnFrontendInformationChange}
          />
          <BasicSelect
            title="How easy is it to maintain 3rd party Frontend?"
            curValue={offChainMaintanceScore.toString()}
            onChange={handelOffChainScoreChange}
            value="id"
            display="name"
            options={selectValues}
          />
        </div>
      </div>
      <PurpleButton
        width="90%"
        height="3rem"
        text="Upload Centralizaion Data"
        onClick={sendPublishReportDataRequest}
        disabled={protocolId <= 0}
      />
    </>
  );
};

export default Centralization;
