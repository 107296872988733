import styles from "./SecInfra.module.css";
import {
  auditCompanies,
  blockchain,
  protocolData,
} from "../../../utils/reportDataTypes";
import React, { useEffect, useState } from "react";
import { getAllBlockchainsURL } from "../../../api";
import { FaTriangleExclamation } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { formatDate } from "../../../utils/textformUtils";
import { sendRequest } from "../../../utils/apiRequest";

interface ISecInfra {
  protocolData: protocolData;
  auditCompanies: auditCompanies[];
}

const getBugBountyLink = (link: string) => {
  if (link.startsWith("https://immunefi.com/")) {
    return (
      <Link key={link} target="_blank" to={link}>
        Immunefi <FaExternalLinkAlt size={10} />
      </Link>
    );
  } else if (link.length > 5) {
    return (
      <Link key={link} target="_blank" to={link}>
        Own <FaExternalLinkAlt size={10} />
      </Link>
    );
  } else {
    return <p style={{ color: "rgba(255, 99, 132, 1)" }}> No Bug Bounty</p>;
  }
};

function formatNumberShort(num: number): string {
  if (num > 999999999) {
    return num.toLocaleString();
  } else if (num > 999999) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num > 999) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
}

const SecInfra = React.forwardRef<HTMLDivElement, ISecInfra>(
  ({ protocolData, auditCompanies }, ref) => {
    const [blockchains, setBlockchains] = useState<blockchain[]>();

    useEffect(() => {
      const fetchData = async () => {
        await sendGetAllBlockchainsRequest();
      };
      fetchData();
    }, [protocolData.secInfra.auditCompanyIDs]);

    const sendGetAllBlockchainsRequest = async () => {
      await sendRequest(
        getAllBlockchainsURL,
        "Something went wrong while getting blockchain!",
        undefined,
        undefined,
        setBlockchains,
      );
    };

    const auditslength = protocolData.pastAudits.audits.length;
    const recentLenght = protocolData.secInfra.recentSecIndicents.length;

    return (
      <>
        <div className={styles.container} ref={ref}>
          <h2>Sec. Infrastructure Overview</h2>
          <div className={styles.alertContainer}>
            <div className={styles.InfoContainer}>
              <p className={styles.InfoContainerText}>
                Current Bug Bounty:{" "}
                {getBugBountyLink(protocolData.secInfra.bugBounty)}
              </p>
              <p className={styles.InfoContainerText}>
                Bug Bounty Max Payout:{" "}
                {formatNumberShort(protocolData.secInfra.bugBountyAmount)} $
              </p>
              <p className={styles.InfoContainerText}>
                Has paid White Hats before:{" "}
                {protocolData.secInfra.paidWhiteHatBefore}
              </p>
              <p className={styles.InfoContainerText}>
                Last Audit Date:{" "}
                {auditslength > 0
                  ? formatDate(
                      protocolData.pastAudits.audits[
                        protocolData.pastAudits.audits.length - 1
                      ].date,
                    )
                  : ""}
              </p>
            </div>
            <div className={styles.NewsContainer}>
              <p>Recent Security Incidents</p>
              <ul className={styles.NewsView}>
                <li className={styles.row}>
                  <p className={styles.text}>Incident</p>
                  <p className={styles.lostAmount}>Amount Lost</p>
                  <p className={styles.date}>Date</p>
                </li>
                {recentLenght > 0
                  ? protocolData.secInfra.recentSecIndicents.map((item) => {
                      let lostAmount = item.lostAmount;
                      return (
                        <li key={item.title} className={styles.row}>
                          <a href={item.link} className={styles.text}>
                            <FaTriangleExclamation
                              className={styles.severityDisplay}
                            />
                            {item.title}
                          </a>
                          <p
                            className={styles.lostAmount}
                            style={
                              lostAmount === 0
                                ? { color: "rgba(75, 192, 192, 1)" }
                                : { color: "rgba(255, 99, 132, 1)" }
                            }
                          >
                            {lostAmount === 0 ? "Insignificant" : lostAmount}
                          </p>
                          <p className={styles.date}>{item.date}</p>
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </div>
          </div>
          <div className={styles.LowerRow}>
            <div className={styles.AuditCompanyLogoContainer}>
              <h3>Secured by</h3>
              <div className={styles.AuditCompanyList}>
                {auditCompanies.map((item) => {
                  return (
                    <div key={item.name} className={styles.AuditCompany}>
                      <img
                        alt=""
                        src={item.logoURL}
                        className={styles.AuditCompanyLogo}
                      />
                      <a rel="noreferrer" target="_blank" href={item.link}>
                        {item.name} <br /> {item.type}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.DeployedChainsContainer}>
              <h3>Depoyed on</h3>
              <div className={styles.AuditCompanyList}>
                {blockchains !== undefined
                  ? blockchains
                      .filter(
                        (obj) =>
                          protocolData.deployedOn.includes(obj.chainId) ||
                          (obj.chainId === 1 &&
                            protocolData.deployedOn.includes(0)),
                      )
                      .map((item) => {
                        return (
                          <div key={item.name} className={styles.AuditCompany}>
                            <img
                              alt=""
                              src={item.logoURL}
                              className={styles.AuditCompanyLogo}
                            />
                            <p>
                              {item.name} <br /> {item.info}
                            </p>
                          </div>
                        );
                      })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default SecInfra;
