import styles from "./AddBlockchain.module.css";
import { blockchain } from "../../../utils/reportDataTypes";
import { useEffect, useState } from "react";
import BasicInput from "../../Reusable/BasicInput";
import { getAllBlockchainsURL, writeBlockchainURL } from "../../../api";
import { tmpBlockChains } from "../../../utils/templateProtocolData";
import { sendRequest } from "../../../utils/apiRequest";

interface IBlockchain {
  setAddBlockchainActive: (addBlockchainActive: boolean) => void;
}

export const AddBlockchain: React.FC<IBlockchain> = ({
  setAddBlockchainActive,
}) => {
  const [blockchain, setBlockchain] = useState<blockchain>(tmpBlockChains[0]);
  const [blockchains, setBlockchains] = useState<blockchain[]>();

  const sendPublishBlockchainRequest = async (request: blockchain) => {
    await sendRequest(
      writeBlockchainURL,
      "Something went wrong!",
      "Blockchain added!",
      request,
      sendGetAllBlockchainsRequest,
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await sendGetAllBlockchainsRequest();
    };
    fetchData();
  }, []);

  const handelBlockchainNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let tmpAuditCompany: blockchain = blockchain;
    tmpAuditCompany.name = e.target.value;
    setBlockchain({ ...tmpAuditCompany });
  };

  const handelBlockchainChainIDChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let tmpAuditCompany: blockchain = blockchain;
    tmpAuditCompany.chainId = Number.parseInt(e.target.value);
    setBlockchain({ ...tmpAuditCompany });
  };

  const handelBlockchainLogoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let tmpAuditCompany: blockchain = blockchain;
    tmpAuditCompany.logoURL = e.target.value;
    setBlockchain({ ...tmpAuditCompany });
  };

  const handelBlockchainInfoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let tmpAuditCompany: blockchain = blockchain;
    tmpAuditCompany.info = e.target.value;
    setBlockchain({ ...tmpAuditCompany });
  };

  const sendGetAllBlockchainsRequest = async () => {
    await sendRequest(
      getAllBlockchainsURL,
      "Something went wrong while getting blockchain!",
      undefined,
      undefined,
      setBlockchains,
    );
  };

  return (
    <>
      <h3 className={styles.header}>Add Blockchain</h3>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <BasicInput
            title={"ChainID"}
            placeholder={"1"}
            onChange={handelBlockchainChainIDChange}
            redBorder={false}
          />
          <BasicInput
            title={"Name"}
            placeholder={"Ethereum"}
            onChange={handelBlockchainNameChange}
            redBorder={false}
          />
          <BasicInput
            title={"LogoURL"}
            placeholder="https://"
            onChange={handelBlockchainLogoChange}
            redBorder={false}
          />
          <BasicInput
            title={"Info"}
            placeholder="Blockchain is an L2...."
            onChange={handelBlockchainInfoChange}
            redBorder={false}
          />
        </div>
        <div className={styles.containerInner}>
          <ul className={styles.CompanieList}>
            <li className={styles.stickyRow}>
              <p className={styles.headerName}>Blockchain</p>
              <p>Chain ID</p>
              <p>Info</p>
            </li>
            {blockchains
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((blockchain) => (
                <li key={blockchain.name} className={styles.row}>
                  <div className={styles.rowNameContainer}>
                    <img
                      alt=""
                      src={blockchain.logoURL}
                      className={styles.image}
                    />
                    <p>{blockchain.name}</p>
                  </div>
                  <p>{blockchain.chainId}</p>
                  <p>{blockchain.info}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <button
        className={styles.saveButton}
        onClick={() => sendPublishBlockchainRequest(blockchain)}
      >
        Save Blockchain
      </button>
      <button
        className={styles.backbutton}
        onClick={() => setAddBlockchainActive(false)}
      >
        Back
      </button>
    </>
  );
};
