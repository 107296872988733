import styles from "./ScoreCalculation.module.css";
import { useState } from "react";
import { deCentralizationScoreSubmittRequest } from "../../../utils/severRequestTypes";
import { writeDeCentralizationScoreURL } from "../../../api";
import BasicSelect from "../../Reusable/BasicSelect";
import { sendRequest } from "../../../utils/apiRequest";
import PurpleButton from "../../Reusable/PurpleButton";

interface IScoreCalculation {
  protocolReportID: number;
  setProgress: (newA: number[]) => void;
}

export const ScoreCalculation: React.FC<IScoreCalculation> = ({
  protocolReportID,
  setProgress,
}) => {
  const [canAdminStealFunds, setCanAdminStealFunds] = useState<number>(0);
  const [progress, setProgressLocal] = useState<number[]>([0, 0]);
  const [canAdminIndirectlyStealFunds, setCanAdminIndirectlyStealFunds] =
    useState<number>(0);
  const [canSubmittDeCenData, setCanSubmittDeCenData] =
    useState<boolean>(false);

  const sendDeCentralizationScoreRequest = async () => {
    const request: deCentralizationScoreSubmittRequest = {
      protocolReportID: protocolReportID,
      canAdminStealFunds: canAdminStealFunds === 1 ? true : false,
      canAdminIndirectlyStealFunds:
        canAdminIndirectlyStealFunds === 1 ? true : false,
    };
    sendRequest(
      writeDeCentralizationScoreURL,
      "Something went wrong while write!",
      "Added Score!",
      request,
    );
  };

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 2]);
    }
  };

  const handleCanAdminStealFundsChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value =
      Number(e.target.value) === 0
        ? canAdminStealFunds
        : Number(e.target.value);
    setCanAdminStealFunds(value);
    setCanSubmittDeCenData(value !== 0 && canAdminIndirectlyStealFunds !== 0);
    setProgressHelper(0);
  };

  const handleCanIndrectlyAdminStealFundsChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value =
      Number(e.target.value) === 0
        ? canAdminIndirectlyStealFunds
        : Number(e.target.value);
    setCanAdminIndirectlyStealFunds(value);
    setCanSubmittDeCenData(canAdminStealFunds !== 0 && value !== 0);
    setProgressHelper(1);
  };

  const selectValues = [
    { name: "", id: 0 },
    { name: "True", id: 1 },
    { name: "False", id: 2 },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <BasicSelect
            title="Can admin steal funds"
            curValue={canAdminStealFunds.toString()}
            onChange={handleCanAdminStealFundsChange}
            value="id"
            display="name"
            options={selectValues}
          />
        </div>
        <div className={styles.containerInner}>
          <BasicSelect
            title="Can admin indirectly steal funds"
            curValue={canAdminIndirectlyStealFunds.toString()}
            onChange={handleCanIndrectlyAdminStealFundsChange}
            value="id"
            display="name"
            options={selectValues}
          />
        </div>
      </div>
      <PurpleButton
        width="100%"
        height="3rem"
        text="Update DeCentralization Score"
        onClick={sendDeCentralizationScoreRequest}
        disabled={!canSubmittDeCenData || protocolReportID <= 0}
      />
    </>
  );
};

export default ScoreCalculation;
