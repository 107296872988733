import styles from "./DarkLightModeButton.module.css";
import { IoMoonSharp, IoSunnyOutline } from "react-icons/io5";

interface IDarkLightModeButton {
  isDark: boolean;
  setIsDark: (isDark: boolean) => void;
}

export const DarkLightModeButton: React.FC<IDarkLightModeButton> = ({
  isDark,
  setIsDark,
}) => {
  const handleChange = () => {
    if (isDark) {
      localStorage.setItem("theme", "light");
    } else {
      localStorage.setItem("theme", "dark");
    }

    // TEMPORARY SOLUTION:
    // Manually dispatch a 'storage' event to trigger updates in the same window
    // This is needed because the 'storage' event doesn't fire for changes
    // made within the same page. In a production environment, consider using
    // a more robust state management solution or a custom event system.
    window.dispatchEvent(new Event("storage"));

    setIsDark(!isDark);
  };

  return (
    <button className={styles.button} onClick={handleChange}>
      <div className={styles.innerBox}>
        {isDark ? <IoMoonSharp /> : <IoSunnyOutline />}
      </div>
    </button>
  );
};
