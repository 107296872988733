import styles from "./PurpleButton.module.css";

interface IPurpleButton {
  text: string;
  onClick?: () => void;
  fontSize?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  fixedsize?: boolean;
  width?: string;
  height?: string;
}

const PurpleButton: React.FC<IPurpleButton> = ({
  text,
  onClick,
  fontSize = "16px",
  type = "button",
  disabled = false,
  fixedsize = false,
  width,
  height,
}) => {
  const buttonStyle = {
    fontSize,
    width: width || undefined,
    height: height || undefined,
  };

  return (
    <button
      type={type}
      disabled={disabled}
      style={buttonStyle}
      onClick={onClick}
      className={fixedsize ? styles.buttonFixed : styles.button}
    >
      {text}
    </button>
  );
};

export default PurpleButton;
