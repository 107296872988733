import React from "react";
import styles from "./BasicRadioInput.module.css";
import { auditCompaniesType } from "../../../utils/reportDataTypes";

export type IRadioButton = {
  title: string;
  value: string;
  type: auditCompaniesType;
};

interface IBasicRadioInput {
  buttons: Array<IRadioButton>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: auditCompaniesType;
}

export const BasicRadioInput: React.FC<IBasicRadioInput> = ({
  buttons,
  onChange,
  value,
}) => {
  return (
    <form className={styles.Form}>
      {buttons.map((button, i) => {
        return (
          <div key={i} className={styles.Select}>
            <div>{button.title}</div>
            <input
              type="radio"
              value={button.value}
              checked={button.type === value}
              onChange={onChange}
            />
          </div>
        );
      })}
    </form>
  );
};
