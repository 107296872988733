import { useState } from "react";
import styles from "./AddProtocol.module.css";
import { BasicInfo } from "../../../components/PublishPageComponents/BasicInfo";
import CodeComplexity from "../../../components/PublishPageComponents/CodeComplexity";
import Centralization from "../../../components/PublishPageComponents/Centralization";
import { protocolData, protocolPreview } from "../../../utils/reportDataTypes";
import { SecInfra } from "../../../components/PublishPageComponents/SecInfra";
import ScoreCalculation from "../../../components/PublishPageComponents/ScoreCalculation";
import SideMenuPublishTool from "../../../components/PublishPageComponents/SideMenu";
import Collapsible from "../../../components/Reusable/Collapsible";

interface IAddProtocolPage {
  protocolPreview: protocolPreview;
  protocolData: protocolData | null;
  setAddProtocolActive: (addProtocolActive: boolean) => void;
}
const AddProtocolPage: React.FC<IAddProtocolPage> = ({
  protocolPreview,
  protocolData,
  setAddProtocolActive,
}) => {
  const [currentProtocol, setCurrentProtocol] =
    useState<protocolPreview>(protocolPreview);
  const [progressBasicInfo, setProgressBasicInfo] = useState<number[]>([0, 6]);

  const [progressSecInfra, setProgressSecInfra] = useState<number[]>([0, 4]);

  const [progressCodeComp, setProgressCodeComp] = useState<number[]>([0, 4]);

  const [progressCentraInfo, setProgressCentraInfo] = useState<number[]>([
    0, 5,
  ]);
  const [progressScoreCalc, setProgressScoreCalc] = useState<number[]>([0, 2]);

  const setCurrentProtocolID = (i: number) => {
    setCurrentProtocol({
      ...currentProtocol,
      rid: i,
    });
  };

  return (
    <>
      <div className={styles.container} id="adminContainer">
        <SideMenuPublishTool
          totalProgressMax={
            progressBasicInfo[1] +
            progressSecInfra[1] +
            progressCodeComp[1] +
            progressCentraInfo[1]
          }
          totalProgressCurrent={
            progressBasicInfo[0] +
            progressSecInfra[0] +
            progressCodeComp[0] +
            progressCentraInfo[0]
          }
          protocolPreview={protocolPreview}
          setAddProtocolActive={setAddProtocolActive}
        />
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <b className={styles.RoundNumber}>1</b>

              <b className={styles.headerText}>Edit Report</b>
              <div className={styles.dividerLine} />
              <b
                className={styles.RoundNumber}
                style={{ color: "#999999", borderColor: "#999999" }}
              >
                2
              </b>
              <b className={styles.headerText} style={{ color: "#999999" }}>
                Report is Reviewed
              </b>
              <div className={styles.dividerLine} />
              <b
                className={styles.RoundNumber}
                style={{ color: "#999999", borderColor: "#999999" }}
              >
                3
              </b>
              <b className={styles.headerText} style={{ color: "#999999" }}>
                Published
              </b>
            </div>
          </div>
          <div className={styles.contentContainerWithOutHeader}>
            <Collapsible
              title={"Add Basic Information"}
              progress={progressBasicInfo}
            >
              <BasicInfo
                protocolData={protocolData}
                protocolId={currentProtocol.rid}
                setProtocolID={setCurrentProtocolID}
                setProgress={setProgressBasicInfo}
              />
            </Collapsible>
            <Collapsible
              title={"Add Security Infrastructure Information"}
              progress={progressSecInfra}
            >
              <SecInfra
                protocolData={protocolData}
                protocolReportID={currentProtocol.rid}
                setProgress={setProgressSecInfra}
              />
            </Collapsible>
            <Collapsible
              title={"Add Code Base Information"}
              progress={progressCodeComp}
            >
              <CodeComplexity
                protocolData={protocolData}
                protocolReportID={currentProtocol.rid}
                setProgress={setProgressCodeComp}
              />
            </Collapsible>
            <Collapsible
              title={"Add Centralization Information"}
              progress={progressCentraInfo}
            >
              <Centralization
                protocolData={protocolData}
                protocolId={currentProtocol.rid}
                setProgress={setProgressCentraInfo}
              />
            </Collapsible>
            <Collapsible title={"Calculate Score"} progress={progressScoreCalc}>
              <ScoreCalculation
                protocolReportID={currentProtocol.rid}
                setProgress={setProgressScoreCalc}
              />
            </Collapsible>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProtocolPage;
