import styles from "./CodeComplexity.module.css";
import BasicInput from "../../Reusable/BasicInput";
import BasicTextArea from "../../Reusable/BasicTextArea";
import BasicList from "../../Reusable/BasicList";
import { useState } from "react";
import { codeDataSubmittRequest } from "../../../utils/severRequestTypes";
import { writeCodeDataURL } from "../../../api";
import PurpleButton from "../../Reusable/PurpleButton";
import { sendRequest } from "../../../utils/apiRequest";
import { protocolData } from "../../../utils/reportDataTypes";

interface ICodeComplexity {
  protocolReportID: number;
  protocolData: protocolData | null;
  setProgress: (newA: number[]) => void;
}

const CodeComplexity: React.FC<ICodeComplexity> = ({
  protocolReportID,
  protocolData,
  setProgress,
}) => {
  const [codeDataObj, setCodeDataObj] = useState<codeDataSubmittRequest>({
    reportID: protocolReportID,
    url: protocolData ? protocolData.codeBaseLink : "",
    basicCodeStructure: protocolData
      ? protocolData.codeComplexity.basicCodeStructure
      : "",
    mostComplexCodeParts: protocolData
      ? protocolData.codeComplexity.mostComplexCodeParts
      : "",
    similarProtocols: [],
  });

  const [progress, setProgressLocal] = useState<number[]>([0, 0, 0, 0]);

  const sendPublishReportDataRequest = async () => {
    console.log(JSON.stringify(codeDataObj));
    await sendRequest(
      writeCodeDataURL,
      "Something went wrong while uploading data!",
      "Code added added!",
      codeDataObj,
      undefined,
    );
  };

  const setProgressHelper = (n: number) => {
    if (progress[n] < 1) {
      const arr = progress;
      arr[n] = 1;
      setProgressLocal(arr);
      setProgress([arr.filter((num) => num === 1).length, 4]);
    }
  };
  if (protocolData && protocolData.codeComplexity !== undefined) {
    if (protocolData.codeBaseLink !== null) {
      if (protocolData.codeBaseLink.length > 0) {
        setProgressHelper(2);
      }
    }
    if (protocolData.codeComplexity.basicCodeStructure !== null) {
      if (protocolData.codeComplexity.basicCodeStructure.length > 0) {
        setProgressHelper(1);
      }
    }
    if (protocolData.codeComplexity.mostComplexCodeParts !== null) {
      if (protocolData.codeComplexity.mostComplexCodeParts.length > 0) {
        setProgressHelper(3);
      }
    }
    if (protocolData.codeComplexity.similarProtocols !== null) {
      if (protocolData.codeComplexity.similarProtocols.length > 0) {
        setProgressHelper(0);
      }
    }
  }
  const handleAddSimilar = () => {
    codeDataObj.similarProtocols.push("");
    setCodeDataObj({ ...codeDataObj });
    setProgressHelper(0);
  };

  const handleSimilarChange =
    (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      codeDataObj.similarProtocols[i] = e.target.value;
      setCodeDataObj({ ...codeDataObj });
    };

  const handleRemoveSimilar = (index: number) => {
    delete codeDataObj.similarProtocols[index];
    setCodeDataObj({ ...codeDataObj });
  };

  const handleCodeStructureChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    codeDataObj.basicCodeStructure = e.target.value;
    setCodeDataObj({ ...codeDataObj });
    setProgressHelper(1);
  };
  const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    codeDataObj.url = e.target.value;
    setCodeDataObj({ ...codeDataObj });
    setProgressHelper(2);
  };

  const handleComplexCodeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    codeDataObj.mostComplexCodeParts = e.target.value;
    setCodeDataObj({ ...codeDataObj });
    setProgressHelper(3);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <BasicTextArea
            title={"Basic code structure"}
            value={codeDataObj.basicCodeStructure}
            placeholder="Code has no .."
            onChange={handleCodeStructureChange}
            redBorder={false}
          />
          <BasicInput
            title={"Codebase Link"}
            value={codeDataObj.url}
            placeholder="https://github..."
            onChange={handleURLChange}
            redBorder={false}
          />
          <BasicTextArea
            title={"Most complex code parts"}
            value={codeDataObj.mostComplexCodeParts}
            placeholder=""
            onChange={handleComplexCodeChange}
            redBorder={false}
          />
        </div>
        <div className={styles.containerInner}>
          <BasicList
            gap={10}
            title="Similar protocols"
            addButtonText="Protocol"
            handleRemove={handleRemoveSimilar}
            handleAdd={handleAddSimilar}
            height={270}
            list={codeDataObj.similarProtocols}
            items={[
              {
                type: "input",
                property: "",
                onChange: handleSimilarChange,
                title: "",
                placeholder: "",
                borderIsValid: [],
              },
            ]}
          />
        </div>
      </div>
      <PurpleButton
        width="90%"
        height="3rem"
        text="Upload Code Data"
        onClick={sendPublishReportDataRequest}
        disabled={protocolReportID <= 0}
      />
    </>
  );
};

export default CodeComplexity;
