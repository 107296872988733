import axios from "axios";
import { toast } from "react-toastify";
import { userData } from "./severRequestTypes";

export async function sendRequest(
  URL: string,
  errorMessage: string,
  successMessage?: string,
  request?: object,
  setResponse?: (any: any) => void,
) {
  const savedUserJSON = localStorage.getItem("user");
  const savedUser: userData | null = savedUserJSON
    ? JSON.parse(savedUserJSON)
    : null;

  const headers = savedUser
    ? {
        "Content-Type": "application/json",
        Authorization: `Bearer ${savedUser?.apiData.key}`,
      }
    : {
        "Content-Type": "application/json",
      };

  try {
    const { data } = await axios.post(URL, request, {
      headers,
    });

    if (data !== undefined && setResponse !== undefined) {
      setResponse(data);
    }
    if (successMessage !== undefined) {
      toast.info(successMessage);
    }
    console.log(data);
    return true;
  } catch (e) {
    const error = e as Error;
    const description = error instanceof Error && 'response' in error 
      ? (error as any).response?.data 
      : error.message;
    toast.error(errorMessage + ": " + description.trim());
    console.log(error);
  }
  return false;
}
