import styles from "./BasicTextArea.module.css";
import { validBorder, inValidBorder } from "../../../utils/adminUtils";
interface IBasicInput {
  title: string;
  value: string | number;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  redBorder: boolean;
}
const BasicTextArea: React.FC<IBasicInput> = ({
  title,
  value,
  placeholder,
  onChange,
  redBorder,
}) => {
  return (
    <div className={styles.basicContainer}>
      <h3>{title}</h3>
      <textarea
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        style={redBorder ? inValidBorder : validBorder}
      />
    </div>
  );
};
export default BasicTextArea;
