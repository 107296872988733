import { MutableRefObject, useEffect, useState } from "react";
import styles from "./SideMenu.module.css";
import { FaCode } from "react-icons/fa6";
import { LuFileCheck } from "react-icons/lu";
import { MdGppGood, MdOutlineDashboard } from "react-icons/md";
import { GoAlert } from "react-icons/go";
import { BiNetworkChart } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const sideMenuItems = [
  { text: "Sec. Infra Overview", value: "", icon: <MdOutlineDashboard /> },
  { text: "Past Audits", value: "", icon: <LuFileCheck /> },
  { text: "Code Structure", value: "", icon: <FaCode /> },
  { text: "Centralization", value: "", icon: <BiNetworkChart /> },
  { text: "Exposure to other Defi", value: "", icon: <GoAlert /> },
  { text: "Recommendation", value: "", icon: <MdGppGood /> },
];

interface ISideMenu {
  refs: Array<MutableRefObject<HTMLElement | null>>;
}

const SideMenu: React.FC<ISideMenu> = ({ refs }) => {
  const navigate = useNavigate();

  const [currentUnderlineRef, setCurrentUnderlineRef] = useState(refs[0]);

  const scrollToComponent = (ref: MutableRefObject<HTMLElement | null>) => {
    if (ref.current) {
      const topPosition =
        ref.current.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const headerOffset = 500;
      let found = false;

      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];
        if (ref.current) {
          const rect = ref.current.getBoundingClientRect();
          if (
            rect.top - headerOffset <= 0 &&
            rect.bottom - headerOffset >= 0 &&
            !found
          ) {
            setCurrentUnderlineRef(ref);
            found = true;
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <div className={styles.sideMenu}>
        <ul className={styles.sideMenuList}>
          {sideMenuItems.map((item, i) => {
            return (
              <li
                key={item.text}
                className={styles.row}
                onClick={() => scrollToComponent(refs[i])}
                style={
                  refs[i] === currentUnderlineRef
                    ? { color: "var(--toastify-color-success)" }
                    : {}
                }
              >
                {item.icon}
                {item.text}
              </li>
            );
          })}
          <li className={styles.row} onClick={() => navigate("/")}>
            <FaArrowLeft />
            Back
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideMenu;
