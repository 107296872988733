import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import styles from "./ShowMoreButton.module.css";

interface IShowMoreButton {
  text: string;
  onClick: () => void;
  showMore: boolean;
}

const ShowMoreButton: React.FC<IShowMoreButton> = ({
  text,
  onClick,
  showMore,
}) => {
  return (
    <div className={styles.showMoreButton} onClick={onClick}>
      <p style={{ width: "95%" }}>{text}</p>
      {!showMore ? (
        <FaArrowDown style={{ width: "5%" }} />
      ) : (
        <FaArrowUp style={{ width: "5%" }} />
      )}
    </div>
  );
};

export default ShowMoreButton;
