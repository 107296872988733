import styles from "./ProgressBar.module.css";
import { useTheme } from "../../../hooks/useTheme";

interface IProgressBar {
  completed: number;
}

const ProgressBar: React.FC<IProgressBar> = ({ completed }) => {
  const isDarkTheme = useTheme();

  return (
    <div
      className={`${styles["progress-bar"]} ${
        isDarkTheme ? styles.darkTheme : ""
      }`}
    >
      <div
        className={styles["progress-bar__filler"]}
        style={{ width: `${completed}%` }}
      >
        <span className={styles["progress-bar__label"]} />
      </div>
    </div>
  );
};

export default ProgressBar;
