import React, { useState } from "react";
import styles from "./AccountPage.module.css";

interface VerifyEmailFormProps {
  email: string;
  onConfirm: (code: string, email: string) => void;
}

const VerifyEmailForm: React.FC<VerifyEmailFormProps> = ({
  email,
  onConfirm,
}) => {
  const [verifyCode, setVerifyCode] = useState("");

  const handleVerifyCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyCode(e.target.value);
  };

  const handleSubmit = () => {
    onConfirm(verifyCode, email);
  };

  return (
    <div className={styles.payment}>
      <div className={styles.loginContainer}>
        <div className={styles.loginInnerContainer}>
          <h3>Verify Email</h3>
          <div className={styles.inputGroup}>
            <label htmlFor="verifyCode">Code</label>
            <input
              id="verifyCode"
              type="text"
              placeholder="000 000"
              onChange={handleVerifyCodeChange}
            />
          </div>
          <div className={styles.description}>
            <p>
              A verification email has been sent to {email}. Check your spam
              folder if you can't find it.
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={`${styles.button} ${styles.primaryButton}`}
              onClick={handleSubmit}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailForm;
