import { useState } from "react";
import PurpleButton from "../../Reusable/PurpleButton";
import styles from "./ConfirmationCodeStep.module.css";
import { sendRequest } from "../../../utils/apiRequest";
import { verifyCodeURL } from "../../../api";
import stepStyles from "../OnboardingStep.module.css";

interface IConfirmationCodeStep {
  handelShowFinish: () => void;
  isProSignUp: boolean;
  email: string;
}

function isValidCode(code: string) {
  var regex = /^\d{6}$|^\d{3} \d{3}$/;
  return regex.test(code);
}

const ConfirmationCodeStep: React.FC<IConfirmationCodeStep> = ({
  email,
  isProSignUp,
  handelShowFinish,
}) => {
  const [confirmCodeError, setConfirmCodeError] = useState<boolean>(false);
  const [confirmationValid, setConfirmationValid] = useState<boolean>(false);
  const [confirmCode, setConfirmCode] = useState<string>("");

  const handleConfirmationCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmCodeError(!isValidCode(e.target.value));
    setConfirmCode(e.target.value);
  };

  const handleConfirm = async () => {
    const request = { email: email, code: confirmCode };
    const success = await sendRequest(
      verifyCodeURL,
      "Error Verify!",
      "Code is valid!",
      request,
    );
    setConfirmationValid(success);
  };

  return (
    <div className={`${styles["confirmation-code-step"]} ${stepStyles["onboarding-step"]}`}>
      <h3 className={styles["confirmation-code-step__title"]}>Confirm Email</h3>
      <div className={styles["confirmation-code-step__container"]}>
        <div className={styles["confirmation-code-step__input-group"]}>
          <label className={styles["confirmation-code-step__label"]}>
            Enter your confirmation code sent to {email}
          </label>
          <input
            value={confirmCode}
            onChange={handleConfirmationCodeChange}
            className={styles["confirmation-code-step__input"]}
            type="text"
            placeholder="000 000"
          />
          {confirmCodeError && (
            <span className={styles["confirmation-code-step__error-msg"]}>
              Invalid code
            </span>
          )}
        </div>

        <div className={styles["confirmation-code-step__buttons"]}>
          <PurpleButton
            disabled={!isValidCode(confirmCode)}
            fixedsize={true}
            text="Confirm"
            onClick={handleConfirm}
          />
        </div>
      </div>
      <div className={styles["confirmation-code-step__buttons"]}>
        <PurpleButton
          fixedsize={true}
          disabled={!confirmationValid}
          text={isProSignUp ? "Next" : "Finish"}
          onClick={handelShowFinish}
        />
      </div>
    </div>
  );
};

export default ConfirmationCodeStep;
