import PurpleButton from "../../Reusable/PurpleButton";
import styles from "./WalletConnectStep.module.css";
import stepStyles from "../OnboardingStep.module.css";

interface IWalletConnectStep {
  handleWalletConnect: () => void;
  handleWalletStepCompleted: () => void;
  wallet: string;
}

const WalletConnectStep: React.FC<IWalletConnectStep> = ({
  wallet,
  handleWalletConnect,
  handleWalletStepCompleted,
}) => {
  return (
    <div className={`${styles["wallet-connect-step"]} ${stepStyles['onboarding-step']}`}>
      <h3 className={styles["wallet-connect-step__title"]}>Connect Wallet</h3>
      <div className={styles["wallet-connect-step__description"]}>
        <p>
          Use Metamask to connect your wallet. You will have to sign a login
          message.
        </p>
      </div>

      <div className={styles["wallet-connect-step__buttons-container"]}>
        <PurpleButton
          fixedsize={true}
          text="Connect Wallet"
          onClick={handleWalletConnect}
        />
        <PurpleButton
          fixedsize={true}
          disabled={wallet.length < 2}
          text="Next"
          onClick={handleWalletStepCompleted}
        />
      </div>
    </div>
  );
};

export default WalletConnectStep;
